var header = $(".js-header"),
    headerTop = $(header).offset().top,
    logo = $(".js-logo"),
    logoTop = $(logo).offset().top,
    gamburger = $(".js-gamburger"),
    gamburgerTop = $(gamburger).offset().top,
    mainBlock = $(".js-main-block"),
    mainBlockHeight = $(".js-main-block").outerHeight(),
    body = $(".js-body"),
    menu = $(".js-menu");

    if ($(mainBlock).length > 0) {
        var  topContent = $(mainBlock).offset().top;
    }
   
    if ($(".js-anchor-first").length > 0) {
        var srm = $(".js-anchor-first").offset().top;
        var srmEnd = $(".js-src-end").offset().top;
        var heightSection = $(".anchor__wrap").height() -  $(".js-src-end").height() - 120 ;
    }

    $(".js-sticky").stick_in_parent();

window.addEventListener("load", function(event) {
    $('html').addClass('page-loaded');
});

$(".js-tab-link").on( "click", function() {
    let tab = $(this).data('tab');

    $(".js-tab-link").removeClass("is-active");
    $(this).addClass("is-active");

    $(".js-tab-block.is-active").hide();
    $(".js-tab-block").removeClass("is-active");

    $(".js-tab-block[data-tab='"+tab+"']").show(); 
    $(".js-tab-block[data-tab="+tab+"]").addClass("is-active");

    $(".js-tab-small-list").removeClass("is-active");
    $(".js-tab-small").removeClass("is-active");
    $(".js-tab-list").removeClass("is-active");
});

$(".js-tab-form-link").on( "click", function() {
    let tab = $(this).data('tab');

    $(".js-tab-form-link").removeClass("is-active");
    $(this).addClass("is-active");

    $(".js-tab-form-block.is-active").hide();
    $(".js-tab-form-block").removeClass("is-active");

    $(".js-tab-form-block[data-tab='"+tab+"']").show(); 
    $(".js-tab-form-block[data-tab="+tab+"]").addClass("is-active");

    $(".js-tab-form-small").removeClass("is-active");
    $(".js-tab-form-list").removeClass("is-active");
});

$(".js-tab-control").on( "click", function() {
    let parents = $(".js-tab-control").parents(".js-tab-small");
    
    $(parents).toggleClass("is-active");
    $(parents).find(".js-tab-list").toggleClass("is-active");
    $(parents).find(".js-tab-small-list").toggleClass("is-active");
});

$(".js-tab-section-link").on( "click", function() {
    let tab = $(this).data('tab');

    $(".js-tab-section-link").removeClass("is-active");
    $(this).addClass("is-active");

    $(".js-tab-section-block.is-active").fadeOut(function() {});
    $(".js-tab-section-block").removeClass("is-active");

    $(".js-tab-section-block[data-tab='"+tab+"']").fadeIn(function() {}); 
    $(".js-tab-section-block[data-tab="+tab+"]").addClass("is-active");
    setTimeout(function () {
    srm = $(".js-anchor-first").offset().top;
    srmEnd = $(".js-src-end").offset().top;
    heightSection = $(".anchor__wrap").height() -  $(".js-src-end").height() - 120 ;
    }, 1000);
});

$(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
        $(".js-up").addClass('is-active');
    } else {
        $(".js-up").removeClass('is-active');
    }
});

$(".js-up").on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '300');
});

$(window).scroll(function(){
    var scroll = $(window).scrollTop(); 
})

$(window).scroll(function(){
    var scroll = $(window).scrollTop(); 
    
    if ((scroll > srm) && (scroll < srmEnd)) {
        $(".anchor").addClass("is-fixed").removeAttr( 'style' );;
    } else if (scroll > srmEnd) {
        $(".anchor").removeClass("is-fixed");
        $(".anchor").css("top" , heightSection);
    } else if ((scroll < srm) && (scroll < srmEnd)){
        $(".anchor").removeClass("is-fixed");
    }

    if ($(window).width() < 1441) {
        if (scroll > 0) {
            $(".js-header").addClass("is-fixed");
        } else {
            $(".js-header").removeClass("is-fixed");
        }
    }
    if ($(window).width() > 1440) {
        colorHeader();
    }

});

$("body").scrollspy({ })

$('.js-anchor').on("click", function () {
	event.preventDefault();

	var sectionName = $(this).data('section'),
	    el = $(".js-section[data-section='" + sectionName + "']");

	$(".js-anchor-nav").slideUp(function () {});
	$('body,html').animate({
		scrollTop: $(el).offset().top
	}, 1000);

	event.preventDefault();
});

if ($('.js-team').length > 0) {
    $(".js-team").slick({
        focusOnSelect: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.js-team-name',
    });
}

if ($('.js-team-name').length > 0) {
    $(".js-team-name").slick({
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: '.js-team',
        prevArrow: '<button type="button" class="slick-prev"><svg class="svg-icon" width="17" height="28" viewBox="0 0 17 28"><use width="17" height="28" viewBox="0 0 17 28" xlink:href="#svg-slider-right"></use></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg class="svg-icon" width="17" height="28" viewBox="0 0 17 28"><use width="17" height="28" viewBox="0 0 17 28" xlink:href="#svg-slider-right"></use></svg></button>',
    });
}


$(gamburger).on( "click", function() {
    $(menu).addClass("is-open");
    $(menu).addClass("is-anim");
});

$(".js-close-menu").on( "click", function() {
    $(menu).removeClass("is-open");

    setTimeout(function () {
        $(menu).removeClass("is-anim");
    }, 600);
});

if ($(window).width() < 768) {
    $(menu).on("click", function (e) {

        if (e.target.classList.contains("js-menu")) {
            $(menu).removeClass("is-open");

            setTimeout(function () {
                $(menu).removeClass("is-anim");
            }, 600);
        }
    });
}

$(".js-menu").swipe({
    swipe: function swipe(event, direction, distance, duration, fingerCount, fingerData) {
        if (direction == "left") {
            $(menu).removeClass("is-open");

            setTimeout(function () {
                $(menu).removeClass("is-anim");
            }, 600);
        }
    }
});

$(".js-level-text").mCustomScrollbar({
    axis:"x",
    advanced:{
        autoExpandHorizontalScroll:true
    },
    callbacks:{
        onInit: function(){
        },
        whileScrolling:function(){ 
            var count = $(this).parents(".js-level__wrap").find(".js-level__item").length,
                Item = $(this).parents(".js-level__wrap").find(".js-level__item"),
                ItemTextWidth =  $(this).parents(".js-level__wrap").find(".level-text__item").outerWidth(),
                widthItem = $(this).parents(".js-level__wrap").find(".js-level__item").outerWidth(),
                parentWidth =  $(this).parents(".js-level__wrap").find(".js-level").outerWidth() - widthItem,
                precent = Math.abs((parentWidth * this.mcs.leftPct)/100),
                activeItem = Math.floor(((this.mcs.leftPct * (count-1))/100)) + 1;

                console.log("precent = " , precent , this.mcs.leftPct , activeItem , ItemTextWidth);

                $(Item).removeClass("is-active");
                $(this).parents(".js-level__wrap").find(".js-level__item[data-item="+activeItem+"]").addClass("is-active");
                $(this).parents(".js-level__wrap").find(".js-level").css("transform", "translateX(-"+((activeItem - 1)*widthItem)+"px)");

                $(this).parents(".js-level__wrap").find(".level-text__container").css("transform", "translateX(-"+((activeItem - 1)*ItemTextWidth)+"px)");
               /* $(Item).removeClass("is-active");
                $(this).parents(".js-level__wrap").find(".js-level__item[data-item="+activeItem+"]").addClass("is-active");
                $(this).parents(".js-level__wrap").find(".js-level").css("transform", "translateX(-"+precent+"px)");*/
        },
        alwaysTriggerOffsets:false
    }
});

if ($(window).width() < 768) {
    $(".js-drop-link").on( "click", function() {
        $(this).next(".js-dropmenu").addClass("is-open");

        return false;
    });
}

$(document).mouseup(function (e){ // событие клика по веб-документу
    var div = $(".js-dropmenu"); // тут указываем ID элемента
    if (!div.is(e.target) // если клик был не по нашему блоку
        && div.has(e.target).length === 0) { // и не по его дочерним элементам
        div.removeClass("is-open");; // скрываем его
    }
});


if ($(window).width() > 1700) {
    if ($(body).hasClass("is-blue")) {
        $(header).addClass("is-white");
        $(gamburger).addClass("is-white");
    }
    
    if (($(body).hasClass("is-blue")) && (logoTop < topContent)){
        $(header).addClass("is-white");
    }

    if (($(body).hasClass("is-blue")) && (logoTop < topContent)){
        $(logoTop).addClass("is-white");
    }

    if (($(body).hasClass("is-blue")) && (gamburgerTop < topContent)) {
        $(gamburger).addClass("is-white");
    }
}

$(".js-anchor-btn").click(function() {
    var elementClick = $(this).attr("href")
    var destination = $(elementClick).offset().top;
    $("html:not(:animated),body:not(:animated)").animate({
        scrollTop: destination
    }, 800);
    $(menu).removeClass("is-open");

    setTimeout(function () {
        $(menu).removeClass("is-anim");
    }, 600);
    return false;
});

function colorHeader() {
    gamburgerTop = $(gamburger).offset().top;
    logoTop = $(logo).offset().top;

    if ($("body").hasClass("is-index")) {
        if (gamburgerTop > mainBlockHeight) {
            $(gamburger).addClass("is-blue");
        } else {
            $(gamburger).removeClass("is-blue");
        }

    } else {
        if (gamburgerTop > mainBlockHeight) {
            $(gamburger).addClass("is-blue");
        } else {
            $(gamburger).removeClass("is-blue");
        }
    }

    if (!$("body").hasClass("is-dark")) {

        if ($("body").hasClass("is-index")) {
            if (logoTop > mainBlockHeight) {
                $("body").removeClass("is-white");
            } else {
                $("body").addClass("is-white");
            }
        } else {
            if (logoTop > mainBlockHeight) {
                $("body").removeClass("is-white");
            } else {
                $("body").addClass("is-white");
            }
        }  
    }
}


var countStep = 4,
    typeStep;

$(".js-item-step-type").on( "click", function() {
    let type = $(this).data('type');

    typeStep = type;

    switch (type) {
        case 'site':
            $(".js-step-number[data-step=3]").addClass("is-hidden");
            break;
        case 'app':
            $(".js-step-number[data-step=3]").removeClass("is-hidden");
            break;
        case 'promotion':
            $(".js-step-number[data-step=3]").removeClass("is-hidden");
            break;
        case 'design':
            $(".js-step-number[data-step=3]").addClass("is-hidden");
            break;
    }
});

$(".js-more-form").on( "click", function() {

    let numberStep  =  $(this).parents(".js-step").data("step");


    $(".js-step-number").removeClass("is-active");

    $(this).parents(".js-step").data("step");
    $(".js-step-number-final").addClass("is-active");
    $(this).parents(".js-step").removeClass("is-active").addClass("is-prev");
    $(this).parents(".form-step__wrap").find(".js-step-final").addClass("is-active").removeClass("is-hidden");

    return false;
});

$(".js-more-form-radio").on( "click", function() {

    let numberStep  =  $(this).parents(".js-step").data("step");

    $(".js-radio-input").val($(this).data("name"));


    $(".js-step-number").removeClass("is-active");

    $(this).parents(".js-step").data("step");
    $(".js-step-number-final").addClass("is-active");
    $(this).parents(".js-step").removeClass("is-active").addClass("is-prev");
    $(this).parents(".form-step__wrap").find(".js-step-final").addClass("is-active").removeClass("is-hidden");

    return false;
});

$(".js-item-step").on( "click", function() {
    let step = $(this).parents(".js-step").data('step'),
        stepBlock = $(this).parents(".js-step"),
        stepNext = step + 1;

        $(".js-step-name").addClass("is-hidden");
        $(".js-step-name[data-type="+typeStep+"]").removeClass("is-hidden");
        $(".js-step-title").addClass("is-hidden");
        $(".js-step-title[data-type="+typeStep+"]").removeClass("is-hidden");
        $(".js-form-step-wrap").addClass("is-hidden");
        $(".js-form-step-wrap[data-type="+typeStep+"]").removeClass("is-hidden");

        console.log("typeStep = " , typeStep);



    $(stepBlock).removeClass("is-active").addClass("is-prev");
    $(".js-step[data-step='"+(step+1)+"']").addClass("is-active");
    $(".js-step-number[data-step='"+step+"']").addClass("is-selected").removeClass("is-active");
    $(".js-step-number[data-step='"+(step+1)+"']").addClass("is-active").addClass("is-selected");

    if (stepNext == 3) {
        $(".js-step-number[data-step='"+(step+2)+"']").addClass("is-active").addClass("is-selected");
    }

   /* let step = $(this).parents(".js-step").data('step'),
        stepBlock = $(this).parents(".js-step"),
        stepNext = step + 1;

    $(stepBlock).removeClass("is-active").addClass("is-prev");
    $(".js-step[data-step='"+(step+1)+"']").addClass("is-active");
    $(".js-step-number[data-step='"+step+"']").addClass("is-selected").removeClass("is-active");
    $(".js-step-number[data-step='"+(step+1)+"']").addClass("is-active").addClass("is-selected");

    if (stepNext == 3) {
        $(".js-step-number[data-step='"+(step+2)+"']").addClass("is-active").addClass("is-selected");
    }*/
});


$(".js-popup-close").on( "click", function() {
    let popup = $(this).parents(".js-popup");

    $(popup).removeClass("is-active");
    $("body").removeClass("is-hidden");
});

$(".js-popup-open").on( "click", function() {
    let name = $(this).data("popup");

    $(".js-popup[data-popup='"+name+"']").addClass("is-active");
    $("body").addClass("is-hidden");

    return false;
});

$(".js-submit").on( "click", function() {
    /*$(".form-step__wrap").hide();
    $(".form-ok").show();*/
    $(this).parents(".js-form-active").find(".js-hidden-form").hide();

    $(".js-form-ok").show();

    setTimeout(function (){
        $(".js-popup").removeClass("is-active");
    }, 2000); 

    setTimeout(function (){
        $(".js-popup").find(".js-hidden-form").show();
        $(".js-form-ok").hide();
    }, 3000); 

    return false;
});


$(".js-step-number").on( "click", function() {
    let step = $(this).data('step');

    $(".js-step-number").removeClass("is-active");
    $(this).addClass("is-active");

    $(".js-step").each(function(indx, element){
        let stepItem = $(this).data('step');

        if (stepItem > step) {
            $(this).removeClass('is-active').removeClass('is-prev');
        } else if (stepItem == step) {
            $(this).addClass('is-active').removeClass('is-prev');
        } else {
            $(this).removeClass('is-active').addClass('is-prev');
        }
    });
});

$( ".js-form-slider" ).slider({
    range: "min",
    min: 1,
    max: 5,
    step: 1
});

$(".js-slider").slider({
    range: true,
    min: 1,
    max: 100,
    step: 1,
    values: [ 0, 100 ],
    slide: function( event, ui ) {
        $(".js-value-min").css("left" , ui.values[ 0 ]+"%");
        $(".js-value-min").find("input").val("от "+ui.values[ 0 ]+"%");
        $(".js-value-max").find("input").val("до "+ui.values[ 1 ]+"%");
        $(".js-value-max").css("right" , 100-ui.values[ 1 ]+"%");
    }
});

var wow = new WOW({});
wow.init();


if ($('#map').length) {
    var init = function init() {
        var myMap = new ymaps.Map("map", {
            center: [47.234090, 39.755114],
            zoom: 18,
            controls: ["zoomControl", "fullscreenControl"]
        });

        var myIconContentLayout = ymaps.templateLayoutFactory.createClass('<div class="marker__layout">\
           <a target=_blank href="https://yandex.ru/maps/39/rostov-na-donu/?from=api-maps&l=map&ll=39.755114%2C47.234090&mode=search&origin=jsapi_2_0&sll=39.755296%2C47.234083&sspn=0.007886%2C0.003102&text=%D1%83%D0%BB%D0%B8%D1%86%D0%B0%2014-%D1%8F%20%D0%9B%D0%B8%D0%BD%D0%B8%D1%8F%2C%2046%D0%90&z=17">Развернуть карту</a>\
           <div class="marker__img"><img src ="./images/common/marker.svg"></div>\
           <a target=_blank href="https://yandex.ru/maps/39/rostov-na-donu/?from=api-maps&l=map&ll=39.755296%2C47.234083&mode=routes&origin=jsapi_2_0&rtext=~47.234090%2C39.755114&rtt=auto&z=18">Построить маршрут</a>\
        </div>');

        var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
        }, {
            iconLayout: myIconContentLayout,
        });

        myMap.geoObjects.add(myPlacemark);
    };

    ymaps.ready(init);
}

$( document ).ready(function() {
    setTimeout(function () {
        $(".js-item-menu").addClass("fadeInUp animated faster");
        $(".js-catalog-link").addClass("fadeInRight animated faster");
        $(".js-catalog-item-anim").addClass("fadeInUp animated faster");
        $(".js-main-block").addClass("is-active");
        $(".js-block-info").addClass("is-active");
    }, 700);

    
    $(window).scroll(function(){
        var scroll = $(window).scrollTop(),
            itemScroll = 0,
            heightWindow = document.documentElement.clientHeight;

        if ($(".js-price-text").length) {
            $(".js-price-text").offset().top
        }    

        if (scroll > (itemScroll - heightWindow)) {
            $(".js-price-text").addClass("is-active");
        }

        $(".js-level-mobile .level__item").each(function( index , elem ) {
            if ($(elem).offset().top - (heightWindow / 3) < scroll) {
                $(".js-level-mobile .level__item").removeClass("is-active");
                $(elem).addClass("is-active");
            }        
        });
    })
   
    $(window).scroll(function(){
        let scroll = $(window).scrollTop();

        if ($("body").hasClass("is-index")) {

            if (scroll >= $(".js-main-block").height()/2) {
                $(".js-catalog-index").addClass("is-active");
            }
        }
    }); 

    /*ANIMATED WORD*/

    function loopWord(item) {
        let widthImg = $(item).find("img:first").width();

        $(item).animate(
            {left: "-"+widthImg*2+"px"}, 52000, "linear", 
            function(){
                $(item).css({left: '0px' });
                setTimeout(loopWord(item), 52000);
            }
        );
    }

    function loopWord1(item) {
        let widthImg = $(item).find("img:first").width();

        $(item).animate(
            {left: "-"+widthImg*2+"px"}, 54000, "linear", 
            function(){
                $(item).css({left: '0px' });
                setTimeout(loopWord1(item), 54000);
            }
        );
    }

    function loopWord2(item) {
        let widthImg = $(item).find("img:first").width();

        $(item).animate(
            {left: "-"+widthImg*2+"px"}, 56000, "linear", 
            function(){
                $(item).css({left: '0px' });
                setTimeout(loopWord2(item), 56000);
            }
        );
    }

    $(".js-main-catalog").on( "mouseenter", function() {
        let itemBody = $(this).find(".js-main-catalog-body");

        itemBody.css("bottom" , ($(this).outerHeight() - itemBody.outerHeight()) + "px")
    });

    $(".js-main-catalog").on( "mouseleave", function() {
        $(this).find(".js-main-catalog-body").removeAttr( "style" );
    })

    loopWord($(".js-word-line"));
    loopWord1($(".js-word-line-1"));
    loopWord2($(".js-word-line-2"));

    $(".js-item-menu a").on( "mouseenter", function() {
        let parentsLink = $(this).parents(".js-item-menu"),
            nameLink = $(parentsLink).data("item");

        $(parentsLink).addClass("is-active");
        $(".js-banner-word[data-item="+nameLink+"]").addClass("is-active")
        loop($(".js-banner-word[data-item="+nameLink+"]"));
    });

    $(".js-item-menu").on( "mouseleave", function() {
        let nameLink = $(this).data("item");

        $(this).removeClass("is-active");
        $(".js-banner-word[data-item="+nameLink+"]").removeClass("is-active");
       
        setTimeout(function () {
            $(".js-banner-word[data-item="+nameLink+"]").stop(true);
        }, 300);
       
    });

    function loop(item) {
        let widthImg = $(".js-banner-word.is-active").find("img:first").width();

        $(".js-banner-word.is-active").animate(
            {left: "-"+widthImg*3+"px"}, 20000, "linear", 
            function(){
                $(".js-banner-word.is-active").css({left: '0px' });
                setTimeout(loop(item), 20000); //repeat after a delay
            }
        );
    }

    $(".js-phone").mask("+7(999)999-99-99");

    $(".js-phone-big").mask("+7(999)999-99-99",
        { 
            clearIncomplete: true,
            completed : function(){
                $(this).parents(".js-phone-block").find(".js-phone-btn").removeAttr("disabled");
            }
        }
    );

    $(".js-phone-big").keyup(function(){
        if ($(".js-phone-big").val().indexOf("_") != '-1') {
            $(this).parents(".js-phone-block").find(".js-phone-btn").attr('disabled', 'disabled');
        }
    });

    $(".js-phone-btn").on( "click", function() {
        $(".js-phone-block-wrap").addClass("is-hidden");
        $(".js-phone-ok").removeClass("is-hidden");

        return false;
    });




    /*TIMELIME PHONE BLOCK*/
   /* let heightPhoneBlock = $(".js-phone-block").height();

    var tl = gsap.timeline({repeat: 2, repeatDelay: 1});

    tl.to(".js-phone-block", {height: 348, duration: 1}, "+=0.5");
    tl.to(".js-phone-block", {height: 0, duration: 1}, "+=1");*/

    /*FORM SCRIPT*/
    $(".js-name").keyup(function(e) {
        $(this).val( $(this).val().replace(/[^a-zA-ZА-Яа-яЁё]/g, '') ); 
    });

    var mySVG = $('.js-svg-draw').drawsvg();
    mySVG.drawsvg('animate');
    
});





